import React from 'react'
import { Fade } from 'react-slideshow-image';

import img_answer_scoring_screen from '../../images/cirt/answer_scoring_screen.png'; // relative path to image 
import img_case_study_screen from '../../images/cirt/case_study_screen.png';
import img_final_screen from '../../images/cirt/final_screen.png';
import img_question_screen from '../../images/cirt/question_screen.png';
import img_roll_animation from '../../images/cirt/roll_animation.png';
import img_roll_screen from '../../images/cirt/roll_screen.png';
import img_scores_and_stats_dialogs from '../../images/cirt/scores_and_stats_dialogs.png';
import img_spin_screen from '../../images/cirt/spin_screen.png';
import img_team_entry_screen from '../../images/cirt/team_entry_screen.png';

const images = [
  img_team_entry_screen,
  img_spin_screen,
  img_case_study_screen,
  img_roll_screen,
  img_roll_animation,
  img_question_screen,
  img_answer_scoring_screen,
  img_final_screen,
  img_scores_and_stats_dialogs
];

class CircularTriviaSlideshow extends React.Component {

  constructor(props) {
    super(props);

    this.fadeProperties = {
      duration: 11000,
      transitionDuration: 500,
      infinite: true,
      indicators: true,
      onChange : this.slideOnChange
    }  

    //Indicate we need a reference to the 3rd party lib
    this.slideRef = React.createRef();

    //Keep track of the current index displayed on the slideshow, bug if you goTo on the current index so avoid
    this.curSlideIndex = 0;
  }

  slideOnChange = (oldIndex, newIndex) => {
    this.curSlideIndex = newIndex;
  }
  
  Slideshow = () => {
    return (
      <div className="slide-container">
        <Fade {...this.fadeProperties} ref={ this.slideRef } >
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[0]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Team Entry Screen</h4>
                  Players decide on their team names and enter up to 4 teams to play at once.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[1]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Spin Screen</h4>
                  Before trivia questions are asked, each team is assigned a case study that their trivia questions will relate to. 
                  A spin animation occurs and ends on a selected case.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[2]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Case Study Screen</h4>
                  Information regarding the team's assigned case study is now shown to be read aloud.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[3]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Roll Screen</h4>
                  The team then rolls to see how many spaces they progress along their case study selection on the board, 
                  each space having a different question associated with it.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[4]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Roll Animation</h4>
                  A smaller circle appears in the middle of the game board circle that spins to select a roll value between 1 and 4.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[5]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Question Screen</h4>
                  Based on the space the rolling team landed on, a particular question is asked regarding their selected case study.
                  All teams may participate in trying to answer the question correctly.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[6]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Answer Scoring Screen</h4>
                  The answer to the question may then be revealed when all teams have placed their answer. 
                  Teams with the correct answers may be given a point, and then the next team gets their turn.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[7]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Game Over Screen</h4>
                  When all cases have been completed, the game ends and the team with the most points is declared the winner.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[8]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Scores and Statistics</h4>
                  At any time during gameplay, team scores, and team statistics may be displayed via a dialog by using the links
                  on the bottom right of the gameplay screen.
                </div>
            </div>
          </div>
        </Fade>
      </div>
    )
  }

  render() {
    return (
        <div>
          {this.Slideshow()}
        </div>
    );
  }
}

export default CircularTriviaSlideshow;