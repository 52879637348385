import React from 'react'
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <div>
      <section id="cta" className="wrapper">
        <div className="inner">
          <h3>Thank you for visiting</h3>
            <p>Thank you for taking the time to check out my past work.<br />
            If you would like to see more, please contact me.<br />
            I would also be happy to discuss future projects. </p>
        </div>
      </section>
    
      <footer id="footer">
				<div className="inner">
          <div className="row content">
            <div className="col-md-3 col-xs-12">
              <h4>Contact</h4>
              <ul className="plain">
                <li>
                  <a href="mailto: jeremy@jeremyglasspoole.com">jeremy@jeremyglasspoole.com</a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/in/jeremy-glasspoole-97733a35" target="_blank" rel="noopener noreferrer">LinkedIn</a>
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-xs-12">
              
            </div>
            
            <div className="col-md-3 col-xs-12">
              <h4>Select Projects</h4>
              <ul className="plain">
                <li><Link to="/causeknot">CauseKnot</Link></li>
                <li><Link to="/invoice-manager">Invoice Manager</Link></li>
                <li><Link to="/confabo">Confabo</Link></li>
                <li><Link to="/circular-trivia">Circular Trivia</Link></li>
              </ul>
            </div>
          </div>
					<div className="copyright">
						&copy; Jeremy Glasspoole, 2019. 
            Photos <a href="https://unsplash.co" target="_blank" rel="noopener noreferrer">Unsplash</a>, 
            Video <a href="https://coverr.co" target="_blank" rel="noopener noreferrer">Coverr</a>, 
            Template by <a href="https://templated.co" target="_blank" rel="noopener noreferrer">Templated</a>.
            <br />
            This site was built using the React framework.
          </div>
				</div>
			</footer>
    </div>
  )
}
