import React, { Component } from 'react'
import PropTypes from 'prop-types';
import InvoiceManagerSlideshow from './InvoiceManagerSlideshow';

export class InvoiceManagerPage extends Component {

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <div id="heading" >
          <h1>Invoice Manager</h1>
        </div>
        <section id="main" className="wrapper">
          <div className="inner">
            <div className="content">
              <h3>About the Project</h3>
              <br />
              The Invoice Manager is a scheduling application designed to streamline and facilitate the generation of client invoices.
              <br /><br />
              An independent contractor who regularly schedules trips and/or appointments with clients would use this tool 
              to manage their client list, schedule, and invoices from a single stand-alone application.
              <br /><br />
              Check out the illustrated presentation of the features that the Invoice Manager application has to offer below.
            </div>

            <div className="content">
              <h3>Application Features</h3>
              <br />
              <InvoiceManagerSlideshow />
            </div>
            <div className="content">
              <h3>Software Details</h3>
              <br />
              <ul className="inside-bullets">
                <li>
                  Coded in C++
                </li>
                <li>
                  Built with the Qt5 Framework
                </li>
                <li>
                  Runs on Windows and MacOS as a desktop application
                </li>
              </ul>
            </div>
            <div className="content">
              <h3>Demo</h3>
              <p>If you are interested in a demo of this application, please contact me.</p>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

// PropTypes
InvoiceManagerPage.propTypes = {
  invmImgStyle: PropTypes.object.isRequired
}

export default InvoiceManagerPage