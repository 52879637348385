import React from 'react';
import { BrowserRouter as Router, Route} from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import LandingPage from './components/LandingPage';
import CauseKnotPage from './components/causeknot/CauseKnotPage';
import ConfaboPage from './components/confabo/ConfaboPage';
import InvoiceManagerPage from './components/invoicemanager/InvoiceManagerPage';
import CircularTriviaPage from './components/circulartrivia/CircularTriviaPage';
import CkImageBackground from './images/ck_logo_small.png';
import CfImageBackground from './images/cf_logo_small.png';
import InvmImageBackground from './images/invm_logo_small.png'; 
import CirtImageBackground from './images/cirt_logo_small.png'; 

import './App.css';
import './assets/css/main.css';

var ckImgStyle = {
  width: "200px",
  height: "200px",
  backgroundImage: `url(${ CkImageBackground })`
};

var cfImgStyle = {
  width: "200px",
  height: "200px",
  backgroundImage: `url(${ CfImageBackground })`
};

var invmImgStyle = {
  width: "200px",
  height: "200px",
  backgroundImage: `url(${ InvmImageBackground })`
};

var cirtImgStyle = {
  width: "200px",
  height: "200px",
  backgroundImage: `url(${ CirtImageBackground })`
};

class App extends React.Component {

  componentDidMount() {
    //Mount template based JS files
    const script1 = document.createElement("script");
    script1.src = "/static/libs/jquery.min.js";
    //script1.async = true;
    document.body.appendChild(script1);

    const script2 = document.createElement("script");
    script2.src = "/static/libs/browser.min.js";
    //script2.async = true;
    document.body.appendChild(script2);

    const script3 = document.createElement("script");
    script3.src = "/static/libs/breakpoints.min.js";
    //script3.async = true;
    document.body.appendChild(script3);

    const script4 = document.createElement("script");
    script4.src = "/static/libs/util.js";
    //script4.async = true;
    document.body.appendChild(script4);

    const script5 = document.createElement("script");
    script5.src = "/static/libs/main.js";
    //script5.async = true;
    document.body.appendChild(script5);
  }

  render() {
    return (
      <Router>
        <div className="App">
          <div className="app-container">
            <Header />
            <Route exact path="/" render={props => (
              <React.Fragment>
                <LandingPage ckImgStyle={ckImgStyle} cfImgStyle={cfImgStyle} invmImgStyle={invmImgStyle} cirtImgStyle={cirtImgStyle} />
              </React.Fragment>
            )} />
            <Route 
              path="/causeknot" 
              render={(props) => <CauseKnotPage {...props} ckImgStyle={ckImgStyle} /> }
              />
            <Route 
              path="/confabo" 
              render={(props) => <ConfaboPage {...props} cfImgStyle={cfImgStyle} /> }
              />
            <Route
              path="/invoice-manager"
              render={(props) => <InvoiceManagerPage {...props} invmImgStyle={invmImgStyle} /> }
              />
            <Route
              path="/circular-trivia"
              render={(props) => <CircularTriviaPage {...props} cirtImgStyle={cirtImgStyle} /> }
              />
            <Footer />
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
