import React, { Component } from 'react'
import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';

export class LandingPage extends Component {

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <MetaTags>
          <title>JeremyGlasspoole.com</title>
          <meta name="description" content="A showcase of some select projects that Jeremy Glasspoole has completed." />
          <meta property="og:title" content="JeremyGlasspoole.com" />
          <meta property="og:image" content="/static/images/og_logo_image.png" />
        </MetaTags>

        <section id="banner">
          <div className="inner">
            <h2>Jeremy Glasspoole</h2>
            <p>&nbsp;Years of experience.&nbsp;<br />
            &nbsp;A passion for excellence.&nbsp;</p>
          </div>
          <video autoPlay loop muted playsInline src="/static/images/banner.mp4"></video>
        </section>

        <section className="wrapper">
          <div className="inner">
            <div className="content">
              <header className="special">
                <h2>About Me</h2>
                <p>
                  Ever since I've been old enough to use one, I've been absolutely fascinated by computers and their capabilities.<br />
                  Since high school the only professional desire I've had is to become a better software engineer.<br />
                  Software to me is a beautiful challenge in a constantly evolving environment.<br />
                  I am dedicated to building small and large scale applications that provide value to the world, and ensuring they are top quality.<br />
                  Driven and passionate, I am always eager to find the next great project to immerse myself in.
                </p>
              </header>
            </div>

            <div className="content">
                <h2>Select Projects</h2><br />
                <p>Below are a collection of real-world applications I have built outside of my full time job to fulfill a purpose or contract.
                  I'm proud of the work I've been able to achieve in my spare time. The knowledge and experience gained from completing them has been invaluable.
                  I encourage you to check out the brief slide presentations for each project and get a feel for what they were all about.
                </p>
            </div>

            <br /><br />
            
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="content">
                  <header>
                  <Link to="/causeknot"><img style={ this.props.ckImgStyle } alt=""></img></Link>
                  <h3>CauseKnot</h3>
                  </header>
                  <p>Learn more about the <Link to="/causeknot">CauseKnot</Link> project.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <div className="content">
                  <header>
                  <Link to="/invoice-manager"><img style={ this.props.invmImgStyle } alt=""></img></Link>
                  <h3>Invoice Manager</h3>
                  </header>
                  <p>Learn more about the <Link to="/invoice-manager">Invoice Manager</Link> project.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="content">
                  <header>
                  <Link to="/confabo"><img style={ this.props.cfImgStyle } alt=""></img></Link>
                  <h3>Confabo</h3>
                  </header>
                  <p>Learn more about the <Link to="/confabo">Confabo</Link> project.
                  </p>
                </div>
              </div>
               <div className="col-md-6 col-xs-12">
                <div className="content">
                  <header>
                  <Link to="/circular-trivia"><img style={ this.props.cirtImgStyle } alt=""></img></Link>
                  <h3>Circular Trivia</h3>
                  </header>
                  <p>Learn more about the <Link to="/circular-trivia">Circular Trivia</Link> project.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

// PropTypes
LandingPage.propTypes = {
  ckImgStyle: PropTypes.object.isRequired,
  cfImgStyle: PropTypes.object.isRequired,
  invmImgStyle: PropTypes.object.isRequired,
  cirtImgStyle: PropTypes.object.isRequired
}

export default LandingPage
