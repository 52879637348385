import React from 'react'
import { Fade } from 'react-slideshow-image';

import img_add_appt from '../../images/invm/app/add_appt.png'; // relative path to image 
import img_add_trip from '../../images/invm/app/add_trip.png';
import img_appt_trip_type_settings from '../../images/invm/app/appt_trip_type_settings.png';
import img_backup_settings from '../../images/invm/app/backup_settings.png';
import img_business_info_settings from '../../images/invm/app/business_info_settings.png';
import img_calendar_display from '../../images/invm/app/calendar_display.png';
import img_client_form from '../../images/invm/app/client_form.png';
import img_client_list from '../../images/invm/app/client_list.png';
import img_invoice_client_select from '../../images/invm/app/invoice_client_select.png';
import img_invoice_directory_settings from '../../images/invm/app/invoice_directory_settings.png';
import img_invoice_options from '../../images/invm/app/invoice_options.png';
import img_invoice_sample from '../../images/invm/app/invoice_sample.png';
import img_main_menu from '../../images/invm/app/main_menu.png';
import img_tax_settings from '../../images/invm/app/tax_settings.png';

const images = [
  img_main_menu,
  img_client_list,
  img_client_form,
  img_calendar_display,
  img_add_trip,
  img_add_appt,
  img_invoice_client_select,
  img_invoice_options,
  img_invoice_sample,
  img_appt_trip_type_settings,
  img_invoice_directory_settings,
  img_business_info_settings,
  img_tax_settings,
  img_backup_settings
];

class InvoiceManagerSlideshow extends React.Component {

  constructor(props) {
    super(props);

    this.fadeProperties = {
      duration: 12500,
      transitionDuration: 500,
      infinite: true,
      indicators: true,
      onChange : this.slideOnChange
    }  

    //Indicate we need a reference to the 3rd party lib
    this.slideRef = React.createRef();

    //Keep track of the current index displayed on the slideshow, bug if you goTo on the current index so avoid
    this.curSlideIndex = 0;
  }

  slideOnChange = (oldIndex, newIndex) => {
    this.curSlideIndex = newIndex;
  }

  showClients = () => {
    if(this.curSlideIndex !== 0)
      this.slideRef.current.goTo(0);
  }

  showScheduling = () => {
    if(this.curSlideIndex !== 3)
      this.slideRef.current.goTo(3);
  }

  showInvoices = () => {
    if(this.curSlideIndex !== 6)
      this.slideRef.current.goTo(6);
  }

  showSettings = () => {
    if(this.curSlideIndex !== 9)
      this.slideRef.current.goTo(9);
  }
  
  Slideshow = () => {
    return (
      <div className="slide-container">
        <Fade {...this.fadeProperties} ref={ this.slideRef } >
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[0]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Main Menu</h4>
                  The main navigational dashboard of the application separates the categories of operations the user can perform.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[1]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Client List</h4>
                  Existing clients are presented in a detailed list sortable by each field. Clients can be added, updated, and deleted from the application.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[2]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Client Form</h4>
                  Each client's name, address info, contact info, general notes, 
                  and default selected appointment &amp; trip types during scheduling are entered and stored.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[3]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Calendar Schedule</h4>
                  Monthly calendar display of the currently scheduled appointments and trips. 
                  One or more days can be selected at once to schedule appointments or trips for.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[4]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Add Trip</h4>
                  After selecting date(s) on the calendar: a client, trip type, and charge details 
                  are selected to schedule a trip for the client.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[5]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Add Appointment</h4>
                  After selecting date(s) on the calendar: a client, appointment type, and charge details 
                  are selected to schedule an appointment for to the client.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[6]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Invoice Client Select</h4>
                  When invoicing clients, the user can decide to search for an individual client or view &amp; select clients by billing period 
                  to facilitate generating all invoices for the current period.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[7]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Invoice Options</h4>
                  Invoice ID, billing dates, payment request date, table headers, extra item additions, and receipt mode are available
                  as modifiable options from an otherwise standard invoice template.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[8]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Invoice Sample</h4>
                  Invoice files are generated as PDF format in a specified directory. All pertinent invoice information is drawn from the 
                  selected client details, calendar schedule, and application settings.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[9]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Appointment &amp; Trip Type Settings</h4>
                  Manage the selectable "types" available for scheduling appointment and trips. 
                  These are also presented when selecting a default appointment or trip type preset for each client. 
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[10]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Invoice Directory Settings</h4>
                  By default, the invoice generation directory is sorted by billing year and client name. 
                  The base directory for all generated invoices is managed in the application settings.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[11]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Business Info Settings</h4>
                  Customized business information fields pertaining to the business generating the invoices.
                  This information will appear on all generated invoices.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[12]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Tax Settings</h4>
                  Modifiable tax percentage rate that is applied to all scheduled appointment and trip charges on the invoice.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[13]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Backup Settings</h4>
                  Application data backup feature to backup all program data by specifying a target external directory to copy to.
                  The user is prompted to backup their data if no backups have been made in the past 90 days.
                </div>
            </div>
          </div>
        </Fade>
      </div>
    )
  }

  render() {
    return (
        <div>
          <ul className="actions fit">
            <li><button onClick={this.showClients} className="button primary fit small">Clients</button></li>
            <li><button onClick={this.showScheduling} className="button primary fit small">Scheduling</button></li>
          </ul>
          <ul className="actions fit">
            <li><button onClick={this.showInvoices} className="button primary fit small">Invoices</button></li>
            <li><button onClick={this.showSettings} className="button primary fit small">Settings</button></li>
          </ul>

          {this.Slideshow()}
        </div>
    );
  }
}

export default InvoiceManagerSlideshow;