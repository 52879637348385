import React, { Component } from 'react'
import PropTypes from 'prop-types';
import CKFrontSlideshow from './CKFrontSlideshow';
import CKAdminSlideshow from './CKAdminSlideshow';

export class CauseKnotPage extends Component {

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <div id="heading" >
          <h1>CauseKnot</h1>
        </div>
        <section id="main" className="wrapper">
          <div className="inner">
            <div className="content">
              <h3>About the Project</h3>
              <br />
              CauseKnot is a donations campaign plugin integrated with the Wordpress Content Management System.<br /><br />
              Using this plugin, site administrators running Wordpress are able to deploy feature-rich campaigns to raise money for a cause.<br /><br />
              Individual fundraisers may sign up as participants, 
              raising money from donors for the campaign on their behalf. 
              Participants can also collaborate on a team to raise as much as possible towards the campaign's fundraising goals.<br /><br />
              Donors have the option to donate directly to the campaign, to an individual campaign participant, or to a team when donating to the cause.<br /><br />
              Other features the CauseKnot donation campaign plugin provides are:<br /><br />
              <ul className="inside-bullets">
                <li>
                  Campaigns with direct donations only, including recurring donation capabilities.
                </li>
                <li>
                  Hosting an event with ticket sale purchases optionally including charitable amounts.
                </li>
                <li>
                  Hosting a gift-based donation campaign as opposed to traditional cash donations.
                </li>
              </ul>
              <br />
              Check out illustrated presentations of the front-end and administrative features that CauseKnot has to offer below.
            </div>

            <div className="content">
              <h3>Front End Features</h3>
              <br />
              <CKFrontSlideshow />
            </div>
            <div className="content">
              <h3>Administrative Features</h3>
              <br />
              <CKAdminSlideshow />
            </div>
            
            <div className="content">
              <h3>Try the Demo</h3>
              <br />
              <div className="row">
                <div className="col-md-12">
                  <a href="http://jeremyglasspoole.com/ck-demo" target="_blank" rel="noopener noreferrer"><img style={ this.props.ckImgStyle } alt=""></img></a>
                  <br />
                  Try the interactive demo, click <a href="http://jeremyglasspoole.com/ck-demo" target="_blank" rel="noopener noreferrer">here</a>.
                </div>
              </div>
            </div>

            <div className="content">
              <h3>Coding</h3>
              <br />
              The programming languages, libraries, and APIs involved in making this project are:<br /><br />
              <ul className="inside-bullets">
                <li>
                  PHP
                </li>
                <li>
                  Javascript
                </li>
                <li>
                  MySQL DB
                </li>
                <li>
                  JQuery
                </li>
                <li>
                  Bootstrap
                </li>
                <li>
                  Wordpress API
                </li>
                <li>
                  Stripe API
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

// PropTypes
CauseKnotPage.propTypes = {
  ckImgStyle: PropTypes.object.isRequired
}

export default CauseKnotPage