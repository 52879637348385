import React from 'react'
import { Fade } from 'react-slideshow-image';

import img_campaign_p2p_home from '../../images/ck/front/campaign/p2p_home.png'; // relative path to image 
import img_donor_form from '../../images/ck/front/donor/donor_form.png';
import img_donor_confirmation from '../../images/ck/front/donor/donor_confirmation.png';
import img_campaign_participant_list from '../../images/ck/front/campaign/prt_list.png';
import img_participant_home from '../../images/ck/front/participant/prt_home.png';
import img_participant_registration from '../../images/ck/front/participant/prt_register.png'; 
import img_participant_edit from '../../images/ck/front/participant/prt_edit.PNG';
import img_participant_dashboard from '../../images/ck/front/participant/prt_dashboard.png'; 
import img_campaign_team_list from '../../images/ck/front/campaign/team_list.png';
import img_team_home from '../../images/ck/front/team/team_home.png';
import img_team_edit from '../../images/ck/front/team/team_edit.png';
import img_campaign_donate_button from '../../images/ck/front/campaign/donate_button.png';
import img_donor_recur_option from '../../images/ck/front/donor/recur_donor_option.png';
import img_donor_recur_form from '../../images/ck/front/donor/recur_donor_form.png';
import img_donor_recur_confirmation from '../../images/ck/front/donor/recur_donor_confirmation.png';
import img_donor_dashboard from '../../images/ck/front/donor/donor_dashboard.png';
import img_event_home from '../../images/ck/front/event/event_home.png';
import img_event_ticket_form from '../../images/ck/front/event/ticket_form.png';
import img_event_ticket_confirmation from '../../images/ck/front/event/ticket_confirmation.png';
import img_gifting_home from '../../images/ck/front/gifting/gifting_home.png';
import img_gifting_gift_form from '../../images/ck/front/gifting/gift_form.png';
import img_gifting_gift_confirmation from '../../images/ck/front/gifting/gift_confirmation.png';

const images = [
  img_campaign_p2p_home,
  img_donor_form,
  img_donor_confirmation,
  img_campaign_participant_list,
  img_participant_home,
  img_participant_registration,
  img_participant_edit,
  img_participant_dashboard,
  img_campaign_team_list,
  img_team_home,
  img_team_edit,
  img_campaign_donate_button,
  img_donor_recur_option,
  img_donor_recur_form,
  img_donor_recur_confirmation,
  img_donor_dashboard,
  img_event_home,
  img_event_ticket_form,
  img_event_ticket_confirmation,
  img_gifting_home,
  img_gifting_gift_form,
  img_gifting_gift_confirmation
];

class CKFrontSlideshow extends React.Component {

  constructor(props) {
    super(props);

    this.fadeProperties = {
      duration: 12500,
      transitionDuration: 500,
      infinite: true,
      indicators: true,
      onChange : this.slideOnChange
    }  

    //Indicate we need a reference to the 3rd party lib
    this.slideRef = React.createRef();

    //Keep track of the current index displayed on the slideshow, bug if you goTo on the current index so avoid
    this.curSlideIndex = 0;
  }

  slideOnChange = (oldIndex, newIndex) => {
    this.curSlideIndex = newIndex;
  }

  showCampaigns = () => {
    if(this.curSlideIndex !== 0)
      this.slideRef.current.goTo(0);
  }

  showEvents = () => {
    if(this.curSlideIndex !== 16)
      this.slideRef.current.goTo(16);
  }

  showGiftings = () => {
    if(this.curSlideIndex !== 19)
      this.slideRef.current.goTo(19);
  }
  
  Slideshow = () => {
    return (
      <div className="slide-container">
        <Fade {...this.fadeProperties} ref={ this.slideRef } >
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[0]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Campaign P2P Home</h4>
                  The home page for a peer-to-peer campaign including participants, 
                  teams, recent donors, progress, and campaign information.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[1]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Donor Form</h4>
                  Form presented to donors making a one-time donation to a campaign, participant,
                  or team. Anonymous donation and donor message options are available.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[2]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Donor Confirmation</h4>
                  Confirmation page shown to a campaign donor with all relevant donation information. 
                  Tax receipt info, credit statement descriptor, included processing fee, 
                  and credited participant or team are all presented here.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[3]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Campaign Participants</h4>
                  List of all participants for a campaign with search functionality
                  for donors to locate a particular profile.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[4]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Participant Home</h4>
                  Participant's public page with a customizable appearance and description that the participant has chosen.
                  Donations to the campaign accredited to this participant's fundraising efforts are submitted here.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[5]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Participant Registration</h4>
                  Sign-up page for individual fundraisers to enter their information to create a participant account 
                  where they can raise money on behalf of the campaign.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[6]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Participant Editor</h4>
                  Account information, image, and password editor available for participants to manage their profile's appearance, goals, and settings
                  once they have signed up.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[7]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Participant Dashboard</h4>
                  Landing page for participants to navigate their account features. 
                  Participants can create and manage, or join a team.
                  They may also personally thank their donors, and update their profile.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[8]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Team List</h4>
                  A list of all teams for a campaign with links to each team page. 
                  Donations may be submitted to teams here or on their respective home page.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[9]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Team Home Page</h4>
                  Team's public page with a customizable appearance and description that the team owner has chosen.
                  Donations to the campaign accredited to this team's fundraising efforts are submitted here.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[10]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Team Editor</h4>
                  Team owners can create and modify a team name, description, motto, and fundraising target,
                  as well as choose custom photos to best represent their team.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[11]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Donate Button</h4>
                  An online donations campaign can have a donations button
                  injected into a custom page template designed by the site administrator.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[12]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Recurring Donations</h4>
                  Online donation campaigns feature the ability for donors to elect for
                  recurring donations over a selected interval until the end of the campaign.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[13]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Recurring Donor Packages</h4>
                  Package selection for recurring donors is presented in the donor form.
                  Also, donor questions may be added by the site administrator to collect extra
                  data from the campaign donors.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[14]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Recurring Donor Confirmation</h4>
                  Confirmation of details for the first recurring donation charge are provided immediately.
                  User accounts are given to recurring donors to access and/or update their information 
                  throughout the remainder of the campaign.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[15]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Donor Dashboard</h4>
                  Landing page for a recurring donor who logs into their donor account.
                  Contains options to change name information or password, update their
                  credit card, or cancel their donor plan.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[16]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Event Home</h4>
                  A hosted event will have a ticket purchase button
                  injected into a custom page template designed by the site administrator.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[17]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Event Ticket Form</h4>
                  Ticket buyers can select which type of ticket and quantity they would like to purchase. 
                  They are provided information about the charitable contribution from their ticket price if applicable.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[18]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Event Ticket Confirmation</h4>
                   Confirmation page shown to an event ticket buyer after their purchase 
                   containing all relevant information to their purchase. 
                </div>
            </div>
          </div>
          <div className="row each-fade" >
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[19]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Gifting Home</h4>
                  A gifting will have a gift purchase button
                  injected into a custom page template designed by the site administrator.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[20]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Gift Form</h4>
                  Gift package select options and information form presented to donors buying gifts 
                  in a "gifting" campaign.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[21]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Gift Confirmation</h4>
                    Confirmation page shown to a gift buyer after their purchase,
                    contains all relevant information to their gift purchase.
                </div>
            </div>
          </div>
        </Fade>
      </div>
    )
  }

  render() {
    return (
        <div>
          <ul className="actions fit">
            <li><button onClick={this.showCampaigns} className="button primary fit small">Campaigns</button></li>
            <li><button onClick={this.showEvents} className="button primary fit small">Events</button></li>
            <li><button onClick={this.showGiftings} className="button primary fit small">Giftings</button></li>
          </ul>

          {this.Slideshow()}
        </div>
    );
  }
}

export default CKFrontSlideshow;