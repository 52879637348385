import React, { Component } from 'react'
import PropTypes from 'prop-types';
import CFFrontSlideshow from './CFFrontSlideshow';
import CFAdminSlideshow from './CFAdminSlideshow';

export class ConfaboPage extends Component {

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <div id="heading" >
          <h1>Confabo</h1>
        </div>
        <section id="main" className="wrapper">
          <div className="inner">
            <div className="content">
              <h3>About the Project</h3>
              <br />
              Confabo is a conference hosting plugin integrated with the Wordpress Content Management System.<br /><br />
              Using this plugin, site administrators running Wordpress are able to deploy conference participant and vendor ticket sale handling
              and enable conference participant networking.<br /><br />
              <br />
              Check out illustrated presentations of the front-end and administrative features that Confabo has to offer below.
            </div>

            <div className="content">
              <h3>Front End Features</h3>
              <br />
              <CFFrontSlideshow />
            </div>
            <div className="content">
              <h3>Administrative Features</h3>
              <br />
              <CFAdminSlideshow />
            </div>
            <div className="content">
              <h3>Coding</h3>
              <br />
              The programming languages, libraries, and APIs involved in making this project are:<br /><br />
              <ul className="inside-bullets">
                <li>
                  PHP
                </li>
                <li>
                  Javascript
                </li>
                <li>
                  MySQL DB
                </li>
                <li>
                  JQuery
                </li>
                <li>
                  Bootstrap
                </li>
                <li>
                  Wordpress API
                </li>
                <li>
                  Stripe API
                </li>
              </ul>
            </div>
            <div className="content">
              <h3>Demo</h3>
              <p>If you are interested in a demo of this application, please contact me.</p>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

// PropTypes
ConfaboPage.propTypes = {
  cfImgStyle: PropTypes.object.isRequired
}

export default ConfaboPage