import React from 'react'
import { Fade } from 'react-slideshow-image';

import img_conference_home from '../../images/cf/front/conference_home.png'; // relative path to image 
import img_meeting_availability from '../../images/cf/front/meeting_availability.png';
import img_meeting_request from '../../images/cf/front/meeting_request.png';
import img_meeting_request_list from '../../images/cf/front/meeting_request_list.png';
import img_networking_registration from '../../images/cf/front/networking_registration.png';
import img_participant_list from '../../images/cf/front/participant_list.png';
import img_pay_form from '../../images/cf/front/pay_form.png';
import img_profile_edit from '../../images/cf/front/profile_edit.png';
import img_profile_home from '../../images/cf/front/profile_home.png';
import img_ticket_confirmation from '../../images/cf/front/ticket_confirmation.png';
import img_ticket_form from '../../images/cf/front/ticket_form.png';
import img_vendor_form from '../../images/cf/front/vendor_form.png';

const images = [
  img_conference_home,
  img_ticket_form,
  img_vendor_form,
  img_pay_form,
  img_ticket_confirmation,
  img_networking_registration,
  img_profile_home,
  img_profile_edit,
  img_meeting_availability,
  img_participant_list,
  img_meeting_request,
  img_meeting_request_list
];

class CFFrontSlideshow extends React.Component {

  constructor(props) {
    super(props);

    this.fadeProperties = {
      duration: 12500,
      transitionDuration: 500,
      infinite: true,
      indicators: true,
      onChange : this.slideOnChange
    }  

    //Indicate we need a reference to the 3rd party lib
    this.slideRef = React.createRef();

    //Keep track of the current index displayed on the slideshow, bug if you goTo on the current index so avoid
    this.curSlideIndex = 0;
  }

  slideOnChange = (oldIndex, newIndex) => {
    this.curSlideIndex = newIndex;
  }

  /*
  showCampaigns = () => {
    if(this.curSlideIndex !== 0)
      this.slideRef.current.goTo(0);
  }

  showEvents = () => {
    if(this.curSlideIndex !== 16)
      this.slideRef.current.goTo(16);
  }

  showGiftings = () => {
    if(this.curSlideIndex !== 19)
      this.slideRef.current.goTo(19);
  }
  */
  
  Slideshow = () => {
    return (
      <div className="slide-container">
        <Fade {...this.fadeProperties} ref={ this.slideRef } >
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[0]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Conference Home</h4>
                  Conference home page containing all relevant conference information, themed image banner, and registration link.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[1]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Ticket Form</h4>
                  Ticket form for conference participants to select their ticket type and quantity, as well as enter a promo code if they have one.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[2]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Vendor Form</h4>
                  Ticket form for conference vendors to select their vendor package type and quantity, as well as enter a promo code if they have one.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[3]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Payment Form</h4>
                  Payment information form mandatory to conference participants and vendors to complete their ticket purchase and register for the conference.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[4]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Purchase Confirmation</h4>
                  Confirmation of ticket purchase details. Conference participants are given a networking access code to create a conference networking account.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[5]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Networking Registration</h4>
                  Conference participants register with their registration access code to create a networking profile where they can connect with other participants.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[6]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Profile Home</h4>
                  Networking participant profile page includes their introduction, job details, and company description.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[7]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Profile Editor</h4>
                  Networking participants can customize their profile to best represent themselves for the conference they are attending.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[8]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Meeting Availability</h4>
                  Networking participants specify blocks of time that they will be available and unavailable to schedule meetings for throughout the duration of the conference.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[9]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Participant List</h4>
                  A list of all networking participants is provided with company details to facilitate networking with others attending.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[10]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Meeting Request</h4>
                  Participants can make meeting requests to each other by selecting available meeting times on another participant's profile.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[11]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Meeting Request List</h4>
                  Participants are given a meeting request list where they can manage incoming and outgoing meeting requests to facilitate booking their conference schedule.
                </div>
            </div>
          </div>
        </Fade>
      </div>
    )
  }

  render() {
    return (
        <div>
          {/*
          <ul className="actions fit">
            <li><button onClick={this.showCampaigns} className="button primary fit small">Campaigns</button></li>
            <li><button onClick={this.showEvents} className="button primary fit small">Events</button></li>
            <li><button onClick={this.showGiftings} className="button primary fit small">Giftings</button></li>
          </ul>
          */}

          {this.Slideshow()}
        </div>
    );
  }
}

export default CFFrontSlideshow;