import React from 'react'
import { Link } from 'react-router-dom';

export default function Header() {
  return (
    <header id="header">
      <Link to="/" className="logo">JeremyGlasspoole.com</Link>
      {
      /*
      Need to import all theme elements including js, scss, etc, to make menu functional
      Include the js files in /public/static/lib/ 
      <nav>
        <Link to="#menu">Menu</Link>
      </nav>
      <nav id="menu">
				<ul className="links">
					<li><Link to="/">Home</Link></li>
          <li><Link to="/causeknot">CauseKnot</Link></li>
				</ul>
			</nav>
      */
     }
    </header>
  )
}
