import React from 'react'
import { Fade } from 'react-slideshow-image';

// relative path to image 

import img_conference_main_menu from '../../images/cf/admin/conference/main_menu.png';
import img_conference_form from '../../images/cf/admin/conference/conference_form.png';
import img_conference_settings from '../../images/cf/admin/conference/conference_settings.png';
import img_conference_coupon_form from '../../images/cf/admin/conference/coupon_form.png';
import img_conference_coupon_settings from '../../images/cf/admin/conference/coupon_settings.png';
import img_conference_networking_settings from '../../images/cf/admin/conference/networking_settings.png';
import img_conference_order_participant_lists from '../../images/cf/admin/conference/order_participant_lists.png';
import img_conference_registration_settings from '../../images/cf/admin/conference/registration_settings.png';
import img_conference_ticket_settings from '../../images/cf/admin/conference/ticket_settings.png';
import img_conference_ticket_type_form from '../../images/cf/admin/conference/ticket_type_form.png';
import img_conference_vendor_form from '../../images/cf/admin/conference/vendor_form.png';
import img_conference_vendor_settings from '../../images/cf/admin/conference/vendor_settings.png';
import img_settings_email from '../../images/cf/admin/settings/email_settings.png';
import img_settings_moderator from '../../images/cf/admin/settings/moderator_settings.png';
import img_settings_tax_payment from '../../images/cf/admin/settings/tax_payment_settings.png';
import img_account_stripe_setup from '../../images/cf/admin/account/stripe_connect_setup.png';
import img_account_activation_help from '../../images/cf/admin/account/activation_help.png'; 
import img_account_owner_setup from '../../images/cf/admin/account/owner_setup.png';
import img_account_financial_setup from '../../images/cf/admin/account/financial_setup.png';
import img_account_license_authorization from '../../images/cf/admin/account/license_auth.png';

const images = [
  img_conference_main_menu,
  img_conference_form,
  img_conference_settings,
  img_conference_ticket_settings,
  img_conference_ticket_type_form,
  img_conference_vendor_settings,
  img_conference_vendor_form,
  img_conference_coupon_settings,
  img_conference_coupon_form,
  img_conference_registration_settings,
  img_conference_networking_settings,
  img_conference_order_participant_lists,
  img_settings_email,
  img_settings_moderator,
  img_settings_tax_payment,
  img_account_stripe_setup,
  img_account_activation_help,
  img_account_owner_setup,
  img_account_financial_setup,
  img_account_license_authorization
];

class CFAdminSlideshow extends React.Component {

  constructor(props) {
    super(props);

    this.fadeProperties = {
      duration: 12500,
      transitionDuration: 500,
      infinite: true,
      indicators: true,
      onChange: this.slideOnChange
    }

    //Indicate we need a reference to the 3rd party lib
    this.slideRef = React.createRef();

    //Keep track of the current index displayed on the slideshow, bug if you goTo on the current index so avoid
    this.curSlideIndex = 0;
  }

  slideOnChange = (oldIndex, newIndex) => {
    this.curSlideIndex = newIndex;
  }

  showConferences = () => {
    if(this.curSlideIndex !== 0)
      this.slideRef.current.goTo(0);
  }

  showSettings = () => {
    if(this.curSlideIndex !== 12)
      this.slideRef.current.goTo(12);
  }

  showAccount = () => {
    if(this.curSlideIndex !== 15)
      this.slideRef.current.goTo(15);
  }

  Slideshow = () => {
    return (
      <div className="slide-container">
        <Fade {...this.fadeProperties} ref={ this.slideRef }>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[0]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Main Menu</h4>
                  Admin landing page for Confabo with a high-level data summary and appropriate navigational links.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[1]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Conference Form</h4>
                  Form for creating and updating a conference. A custom page URL can be specified for the conference's public home page.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[2]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Conference Settings</h4>
                  Titles, navigational links, banner theme, and social media links may be set and modified in conference settings.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[3]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Ticket Settings</h4>
                  Ticket types and purchase instructions for conference participant registration are managed in ticket settings.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[4]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Ticket Type Form</h4>
                  Each ticket type's name, quantity available, cost, purchase limits, and sale times are all customizable for conference participant registration.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[5]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Vendor Settings</h4>
                  Vendor pass types and purchase instructions for conference vendors are managed in vendor settings.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[6]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Vendor Pass Form</h4>
                  Each vendor pass' name, quantity available, cost, purchase limits, sale times, and whether or not a participant ticket is required 
                  are all customizable for conference vendor registration.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[7]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Coupon Settings</h4>
                  Coupons for conference promotions to reduce ticket cost on conference or vendor registration are managed in coupon settings.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[8]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Coupon Form</h4>
                  Coupon code, optional expiry, and quantity limit are all customizable. Coupons may apply to tickets, vendor passes or both.
                  They can be either a percentage or a dollar value reduction.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[9]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Registration Settings</h4>
                  Registration for the conference is initially inactive and can be activated when ticket purchases are ready to go live.
                  Instructions and information in the registration form can be tailored to the conference needs.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[10]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Networking Settings</h4>
                  Networking, i.e., conference participants booking meetings with each other according to their availability, can be
                  activated or deactivated. Blocks of time during the conference can be disabled from networking entirely.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[11]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Information Lists</h4>
                  Detailed lists of conference ticket sales, vendor pass sales, and networking participants are provided and modifiable by the administrator.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[12]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Email Settings</h4>
                  Email communication can be toggled on or off, and 
                  the appropriate SMTP email server details are entered
                  to enable email communication.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[13]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Confabo Moderators</h4>
                  Moderator access may be granted to multiple accounts to distribute administrative responsibilities.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[14]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Tax &amp; Payment Settings</h4>
                  Tax name and percentage applicable can be set and integrated with purchase amounts. 
                  A credit statement descriptor for charges made can be tailored to the conference.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[15]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Stripe Connect Setup</h4>
                  Basic account information is required to initialize
                  a Stripe Connect payment processing account.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[16]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Activation Help</h4>
                  Guidance is provided to the appropriate forms 
                  to setup and use Stripe Connect payment processing.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[17]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Account Owner Setup</h4>
                  Account owner information is required to setup
                  Stripe Connect payment processing.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[18]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Financial Setup</h4>
                  A target bank account is required to enable
                  direct deposit from Stripe Connect.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[19]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>License Key Registration</h4>
                  On initial setup, a license key is required to 
                  access the Confabo software.
                </div>
            </div>
          </div>
        </Fade>
      </div>
    )
  }

  render() {
    return (
      <div>
        {
        <ul className="actions fit">
          <li><button onClick={this.showConferences} className="button primary fit small">Conferences</button></li>
          <li><button onClick={this.showSettings} className="button primary fit small">Settings</button></li>
          <li><button onClick={this.showAccount} className="button primary fit small">Account</button></li>
        </ul>
        }

        { this.Slideshow() }
      </div>
    )
  }
}

export default CFAdminSlideshow;