import React from 'react'
import { Fade } from 'react-slideshow-image';

// relative path to image 

import img_general_menu from '../../images/ck/admin/general/main_menu.png';
import img_general_defaults from '../../images/ck/admin/general/default_settings.png';
import img_general_email_settings from '../../images/ck/admin/general/email_settings.png';
import img_general_email_templates from '../../images/ck/admin/general/email_templates.png';
import img_general_moderator_settings from '../../images/ck/admin/general/moderator_settings.png';
import img_general_permalink_settings from '../../images/ck/admin/general/permalink_settings.png';
import img_general_tax_receipt_settings from '../../images/ck/admin/general/tax_receipt_settings.png';
import img_campaign_create from '../../images/ck/admin/campaign/create_campaign.png';
import img_campaign_lists from '../../images/ck/admin/campaign/campaign_lists.png';
import img_campaign_donations from '../../images/ck/admin/campaign/donation_list.png';
import img_campaign_general_settings from '../../images/ck/admin/campaign/general_settings.png';
import img_campaign_more_settings from '../../images/ck/admin/campaign/more_settings.png';
import img_campaign_participant_team_settings from '../../images/ck/admin/campaign/participant_team_settings.png';
import img_campaign_recur_donor_settings from '../../images/ck/admin/campaign/recur_donor_settings.png';
import img_event_create from '../../images/ck/admin/event/create_event.png';
import img_event_lists from '../../images/ck/admin/event/event_lists.png';
import img_event_settings from '../../images/ck/admin/event/event_settings.png';
import img_event_ticket_type_settings from '../../images/ck/admin/event/ticket_type_settings.png';
import img_gifting_create from '../../images/ck/admin/gifting/create_gifting.png';
import img_gifting_settings_gifters from '../../images/ck/admin/gifting/settings_gifters.png';
import img_account_stripe_setup from '../../images/ck/admin/account/stripe_connect_setup.png';
import img_account_activation_help from '../../images/ck/admin/account/activation_help.png'; 
import img_account_owner_setup from '../../images/ck/admin/account/owner_setup.png';
import img_account_financial_setup from '../../images/ck/admin/account/financial_setup.png';
import img_account_license_authorization from '../../images/ck/admin/account/license_auth.png';

const images = [
  img_general_menu,
  img_campaign_create,
  img_campaign_lists,
  img_campaign_donations,
  img_campaign_general_settings,
  img_campaign_more_settings,
  img_campaign_participant_team_settings,
  img_campaign_recur_donor_settings,
  img_event_create,
  img_event_lists,
  img_event_settings,
  img_event_ticket_type_settings,
  img_gifting_create,
  img_gifting_settings_gifters,
  img_general_defaults,
  img_general_tax_receipt_settings,
  img_general_email_settings,
  img_general_email_templates,
  img_general_moderator_settings,
  img_general_permalink_settings,
  img_account_stripe_setup,
  img_account_activation_help,
  img_account_owner_setup,
  img_account_financial_setup,
  img_account_license_authorization
];

class CKAdminSlideshow extends React.Component {

  constructor(props) {
    super(props);

    this.fadeProperties = {
      duration: 12500,
      transitionDuration: 500,
      infinite: true,
      indicators: true,
      onChange: this.slideOnChange
    }

    //Indicate we need a reference to the 3rd party lib
    this.slideRef = React.createRef();

    //Keep track of the current index displayed on the slideshow, bug if you goTo on the current index so avoid
    this.curSlideIndex = 0;
  }

  slideOnChange = (oldIndex, newIndex) => {
    this.curSlideIndex = newIndex;
  }

  showCampaigns = () => {
    if(this.curSlideIndex !== 0)
      this.slideRef.current.goTo(0);
  }

  showEvents = () => {
    if(this.curSlideIndex !== 8)
      this.slideRef.current.goTo(8);
  }

  showGiftings = () => {
    if(this.curSlideIndex !== 12)
      this.slideRef.current.goTo(12);
  }

  showSettings = () => {
    if(this.curSlideIndex !== 14)
      this.slideRef.current.goTo(14);
  }

  showAccountSetup = () => {
    if(this.curSlideIndex !== 19)
      this.slideRef.current.goTo(20);
  }

  Slideshow = () => {
    return (
      <div className="slide-container">
        <Fade {...this.fadeProperties} ref={ this.slideRef }>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[0]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Main Menu</h4>
                  Admin landing page for CauseKnot with a high-level data summary and
                  appropriate navigational links.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[1]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Campaign Form</h4>
                  Form for creating and updating a donations campaign. 
                  A custom page URL can be specified for the campaign's public home page.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[2]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Campaign Lists</h4>
                  Detailed lists for campaign, participant, and team data
                  with create and update functionality.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[3]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Campaign Donations</h4>
                  Categorized list of campaign donations with all
                  relevant meta data and fee breakdown. 
                  Donation data may be exported as a CSV file.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[4]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Campaign Settings</h4>
                  General campaign options for activating donations, managing
                  front-end appearance, and permitting tax receipts.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[5]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Campaign Settings Pt.2</h4>
                  Tailored questions may be added to front-end donor forms
                  to gather business data, and a credit statement descriptor
                  can be customized for transactions made to the campaign.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[6]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Participant &amp; Team Settings</h4>
                  Customized participant sign-up types and questions 
                  may be added to the campaign registration form. 
                  Also, teams may be activated or de-activated altogether.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[7]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Recurring Donor Settings</h4>
                  Recurring donations can be activated for non peer-to-peer campaigns, 
                  and donation plan packages are customizable.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[8]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Event Form</h4>
                  Form for creating and updating a charitable event.
                  A custom page URL can be specified for the event's public home page.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[9]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Event Lists</h4>
                  Detailed lists for events and event ticket data with 
                  create and update functionality. Ticket purchase data
                  may be exported as a CSV file.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[10]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Event Settings</h4>
                  Ticket purchase activation, custom front-end display fields, 
                  and tailored credit statement descriptor are managed in event settings.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[11]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Ticket Type Settings</h4>
                  Ticket name, cost, quantity available,
                  and charitable amount are all customizable for the event. 
                  Multiple ticket type sales are permitted.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[12]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Gifting Form</h4>
                  Form for creating and updating a gift-based donations campaign, or a "Gifting".
                  Gift package types and quantities may be specified.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[13]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Gifting Settings &amp; Donor List</h4>
                  Gifting activation, display fields, tax receipts, 
                  and credit statement descriptor are all customizable in gifting settings. 
                  A detailed list of gift donors with fee and package breakdown is provided.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[14]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Default Settings</h4>
                  Donation suggested amounts, donor minimum amount,
                  and default credit statement descriptor are plugin general settings.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[15]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Tax Receipt Settings</h4>
                  Charitable tax receipts can be emailed to donors of a campaign. 
                  Charitable registration number, and organization data images included 
                  are set by the admin. 
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[16]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Email Settings</h4>
                  Email communication can be toggled on or off, and 
                  the appropriate SMTP email server details are entered
                  to enable email communication.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[17]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Email Templates</h4>
                  CauseKnot provides default email templates to be sent on specific interactions,
                  and the admin has the ability to customize the email content.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[18]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>CauseKnot Moderators</h4>
                  Moderator access may be granted to multiple accounts for the entire plugin scope
                  or for only specific campaigns to distribute administrative responsibilities.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[19]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Campaign Permalinks</h4>
                  URL segments for peer-to-peer campaigns may be customized for branding 
                  or admin preferences.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[20]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Stripe Connect Setup</h4>
                  Basic account information is required to initialize
                  a Stripe Connect payment processing account.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[21]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Activation Help</h4>
                  Guidance is provided to the appropriate forms 
                  to setup and use Stripe Connect payment processing.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[22]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Account Owner Setup</h4>
                  Account owner information is required to setup
                  Stripe Connect payment processing.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[23]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>Financial Setup</h4>
                  A target bank account is required to enable
                  direct deposit from Stripe Connect.
                </div>
            </div>
          </div>
          <div className="row each-fade">
            <div className="image-container col-md-8 col-xs-12">
              <img src={images[24]} alt="" />
            </div>
            <div className="caption-container col-md-4 col-xs-12">
                <div className="caption-text">
                  <h4>License Key Registration</h4>
                  On initial setup, a license key is required to 
                  access the CauseKnot software.
                </div>
            </div>
          </div>
        </Fade>
      </div>
    )
  }

  render() {
    return (
      <div>
        <ul className="actions fit">
          <li><button onClick={this.showCampaigns} className="button primary fit small">Campaigns</button></li>
          <li><button onClick={this.showEvents} className="button primary fit small">Events</button></li>
          <li><button onClick={this.showGiftings} className="button primary fit small">Giftings</button></li>
        </ul>

        <ul className="actions fit">
          <li><button onClick={this.showSettings} className="button primary fit small">General Settings</button></li>
          <li><button onClick={this.showAccountSetup} className="button primary fit small">Account Setup</button></li>
        </ul>

        { this.Slideshow() }
      </div>
    )
  }
}

export default CKAdminSlideshow;