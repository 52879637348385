import React, { Component } from 'react'
import PropTypes from 'prop-types';
import CircularTriviaSlideshow from './CircularTriviaSlideshow';

export class CircularTriviaPage extends Component {

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <div id="heading" >
          <h1>Circular Trivia</h1>
        </div>
        <section id="main" className="wrapper">
          <div className="inner">
            <div className="content">
              <h3>About the Project</h3>
              <br />
              Circular Trivia is a case study based trivia game involving 12 different cases with 12 questions each.
              <br /><br />
              Teams answer questions asked in an open-room environment at the same time, and correct answer points are tallied in game.
              <br /><br />
              Check out the illustrated presentation of the features that the Circular Trivia has to offer below.
            </div>

            <div className="content">
              <h3>Game Features</h3>
              <br />
              <CircularTriviaSlideshow />
            </div>

            <div className="content">
              <h3>Try the Demo</h3>
              <br />
              <div className="row">
                <div className="col-md-12">
                  <a href="https://jeremyglasspoole.com/circular-trivia-demo" target="_blank" rel="noopener noreferrer"><img style={ this.props.cirtImgStyle } alt=""></img></a>
                  <br />
                  Try the interactive demo, click <a href="https://jeremyglasspoole.com/circular-trivia-demo" target="_blank" rel="noopener noreferrer">here</a>.
                </div>
              </div>
            </div>

            <div className="content">
              <h3>Coding</h3>
              <br />
              The programming languages, libraries, and APIs involved in making this project are:<br /><br />
              <ul className="inside-bullets">
                <li>
                  Javascript
                </li>
                <li>
                  KineticJS HTML5 Canvas JS Framework
                </li>
                <li>
                  JQuery
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

// PropTypes
CircularTriviaPage.propTypes = {
  cirtImgStyle: PropTypes.object.isRequired
}

export default CircularTriviaPage